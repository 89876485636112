@import './_styles/colors';
@import './_styles/constants';
@import './_styles/fonts';
@import './_styles/reset';

html,
body {
  height: auto;
  margin: 0;
  font-size: 10px;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

button {
  color: $text-color;
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'CamptonBold';
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

h3 {
  font-size: 2rem;
  font-weight: bold;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.6rem;
  font-weight: bold;
}

h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

body {
  font-size: 1.6rem;
}

main {
  margin-top: 8rem;
}

a {
  color: $primary-color;
}

button.plain {
  border: 0;
  background: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  outline: 0;
}

i.icon svg path {
  fill: $white;
}
