@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.publicity-wrapper {
  margin-top: $mobile-navbar-height;
  padding: 0 1.6rem 2.4rem 1.6rem;

  @include breakpoint(desktop) {
    display: flex;
    margin: 18rem 11.2rem;
    width: 80%;
  }

  .img {
    display: block;
    max-height: 24rem;
    height: 24rem;
    background-color: transparent;
    margin: 0 auto 2.4rem auto;

    @include breakpoint(tablet) {
      max-height: 57.6rem;
      height: initial;
    }

    @include breakpoint(desktop) {
      max-height: 54rem;
    }
  }

  .info-wrapper {
    @include breakpoint(desktop) {
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 30%;
    }

    h2,
    p {
      text-align: center;

      @include breakpoint(tablet) {
        text-align: left;
        padding: 0 9.6rem;
      }

      @include breakpoint(desktop) {
        padding: 0;
      }
    }

    h2 {
      margin-bottom: 1.6rem;
    }

    p {
      margin-bottom: 1rem;
    }

    a {
      margin: 1rem auto;
      display: flex;
      justify-content: center;

      @include breakpoint(desktop) {
        margin: 1rem 0 0 0;
        justify-content: flex-start;
      }
    }

    .continue {
      font-family: CamptonSemiBold;
      background-color: $text-color;
      color: $white;
      padding: 1rem 2rem;
      max-width: 20rem;
      font-weight: bold;
    }
  }
}
