@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.authorized-layout {
  .leaderboard {
    display: none;

    @include breakpoint(monitor) {
      display: block;
      max-width: 72.8rem;
      max-height: 9rem;
      width: 72.8rem;
      height: 9rem;
      background-color: transparent;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 10.4rem;
    }
  }

  .skyscraper {
    display: none;

    @include breakpoint(big-monitor) {
      display: block;
      max-width: 16rem;
      max-height: 60rem;
      width: 16rem;
      height: 60rem;
      background-color: transparent;
      position: fixed;
      top: 28.2rem;

      &.left {
        left: 9.6rem;
      }

      &.right {
        right: 9.6rem;
      }
    }
  }

  .disclaimer {
    font-size: 1.2rem;
    width: 90%;
    text-align: center;
    padding: 1rem 2rem;
    color: $grey;
    margin: 0 auto 1rem auto;

    @include breakpoint(tablet) {
      width: 100%;
    }

    a,
    span {
      text-decoration: underline;
      color: $grey;
    }
  }
}
