$primary-color: #002548;
$text-color: #00395d;
$yellow: #ffcc00;
$green: rgba(126, 211, 33, 1);
$green-light: rgba(126, 211, 33, 0.04);
$red: rgba(228, 4, 4, 1);
$red-light: rgba(228, 4, 4, 0.04);
$correct-answer: #fafdf6;
$wrong-answer: #f5f7f9;
$blue-light: rgba(0, 57, 93, 0.64);
$background-transparent: rgba(0, 57, 93, 0.95);
$border: rgba(0, 57, 93, 0.08);
$grey: #999fa3;
$grey-light: #ebeff2;
$grey-dark: #56585c;
$grey-blue: #5c8097;
$white: #ffffff;
$black: #000000;
$shadow: rgba(34, 36, 38, 0.15);
