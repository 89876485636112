@import '../../_styles/breakpoints';

.pinch-zoom-wrapper {
  width: 100%;
  position: relative;
  top: -0.3rem;

  @include breakpoint(tablet) {
    margin-top: 2.4rem;
    max-height: 43.2rem;
    max-width: 576px;
  }

  @include breakpoint(desktop) {
    margin: 0 6.4rem 0 0;
  }
}
