@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.wrong-wrapper {
  width: 90%;
  text-align: center;
  background-color: $red;
  color: $white;
  position: fixed;
  top: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  z-index: 10001;

  @include breakpoint(tablet) {
    width: initial;
  }

  @include breakpoint(desktop) {
    left: initial;
    right: 1.6rem;
    top: 2.7rem;
    transform: translateX(0);
  }
}

.question-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: $mobile-navbar-height 0 2rem 0;

  &.is-kbc-behaviour {
    margin-top: $kbc-navbar-height;
  }

  @include breakpoint(tablet) {
    align-items: center;
  }

  @include breakpoint(desktop) {
    align-items: flex-start;
    flex-direction: row;
    margin-top: 19.2rem;
  }

  img {
    max-width: 100%;
    max-height: 25rem;
    background-color: transparent;
    margin: 0 auto;
    cursor: pointer;

    @include breakpoint(tablet) {
      max-height: 43.2rem;
      max-width: 57.6rem;
    }
  }

  .answers-wrapper {
    padding: 0 1.6rem 1.6rem 1.6rem;
    margin-top: 0.4rem;

    @include breakpoint(tablet) {
      padding: 0;
      max-width: 57.6rem;
    }

    .info {
      font-family: 'PT-Sans-Narrow';
      margin: 1.6rem 0 0.5rem;
      font-size: 1.1rem;

      @include breakpoint(desktop) {
        display: initial;
        font-family: 'PT-Sans-Narrow';
        color: $blue-light;
      }

      &.example {
        font-family: 'PT-Sans-Narrow-Bold';
        margin-top: 1.6rem;
        font-size: 1.1rem;
        background-color: $yellow;
        padding: 0.5rem;
        color: $text-color;

        @include breakpoint(desktop) {
          display: initial;
        }
      }
    }

    h2 {
      font-size: 2rem;
      color: $primary-color;
      margin: 0.8rem 0 1.6rem;

      @include breakpoint(desktop) {
        margin-top: 1rem;
      }
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: center;
    margin: 2.4rem;

    @include breakpoint(tablet) {
      margin: 4.8rem;
    }

    &:first-child {
      margin-right: 2.4rem;
    }

    .as-text {
      color: $text-color;
    }
  }
}
