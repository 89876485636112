@import '../_styles/colors';
@import '../_styles/breakpoints';

.countdown {
  background-color: $background-transparent;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  .title {
    font-size: 2.4rem;
    font-family: 'CamptonBold';

    @include breakpoint(desktop) {
      font-size: 4rem;
    }
  }

  .sentence {
    font-size: 1.6rem;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
    }
  }

  .count {
    font-size: 4.8rem;
    font-family: 'CamptonBold';

    @include breakpoint(desktop) {
      font-size: 8rem;
    }
  }
}
