@import '../../../_styles/colors';
@import '../../../_styles/breakpoints';

.img-modal {
  background-color: $background-transparent;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  .close-icon {
    display: none;
    cursor: pointer;

    i svg path {
      fill: $text-color;
    }

    @include breakpoint(desktop) {
      display: block;
      position: fixed;
      top: 12rem;
      right: 2.2rem;
      z-index: 1;
    }
  }

  img {
    width: 100%;

    @include breakpoint(tablet) {
      width: 70rem;
    }
  }

  button.close {
    position: fixed;
    bottom: 3.2rem;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(desktop) {
      display: none;
    }
  }
}
