@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.filler {
  width: 0;
  background-color: $yellow;
  height: $progressbar-height;
  position: fixed;
  top: $mobile-navbar-height - $progressbar-height;
  z-index: 10000;

  &.is-kbc-behaviour {
    top: $kbc-navbar-height - $progressbar-height;
  }

  @include breakpoint(desktop) {
    top: $desktop-navbar-height - $progressbar-height;
  }

  &.progression {
    width: 100%;
  }
}
