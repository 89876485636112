@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

$padding: 1.1rem 2.4rem;
$icon-height: 2rem;

.ui.button {
  padding: $padding;
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'CamptonSemiBold';

  > :not(:first-child) {
    margin-left: 0.8rem;

    @include breakpoint(tablet) {
      margin: -0.2rem 0 0 1.5rem;
    }
  }

  i,
  svg {
    width: $icon-height;
    height: $icon-height;
  }
}

@mixin setColorTo($color) {
  color: $color;
  &:hover,
  &:focus {
    outline: transparent;
  }
}

button.as-text {
  padding: $padding;
  background: transparent;
  border: none;
  font-size: 1.6rem;
  font-weight: bold;
  height: 100%;
  text-align: center;
  cursor: pointer;
  font-family: $font-family;
  white-space: nowrap;
  color: $white;

  &.disabled {
    opacity: 0.4;
  }
}
