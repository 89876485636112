@import '../../../_styles/colors';
@import '../../../_styles/breakpoints';

.stop-modal {
  background-color: $white;
  color: $text-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;

  @include breakpoint(tablet) {
    background-color: $background-transparent;
  }

  h2 {
    margin-bottom: 2.4rem;
  }

  .info-wrapper {
    max-width: 64rem;
    background-color: $white;
    padding: 1.6rem;

    @include breakpoint(tablet) {
      padding: 4rem;
    }

    @include breakpoint(desktop) {
      padding: 8rem 8rem 4.8rem 8rem;
    }
  }

  .buttons {
    display: flex;
    position: fixed;
    bottom: 3.2rem;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
      position: initial;
      left: initial;
      transform: initial;
      background-color: $white;
      width: 64rem;
      justify-content: center;
      padding-bottom: 4.8rem;
    }

    .as-text {
      color: $text-color;

      @include breakpoint(tablet) {
        color: $white;
      }

      @include breakpoint(tablet) {
        color: $text-color;
      }
    }
  }
}
