@import '../../_styles/colors';
@import '../../_styles/constants';

.input-wrapper {
  &.required label:after {
    content: '*';
    font-size: 2.5rem;
    color: $red;
    margin-left: 0.3rem;
    margin-top: 0.5rem;
  }

  label:not(:last-child) {
    display: flex;
    align-items: center;
    height: $input-icon-height;
    margin-bottom: $input-label-bottom-margin;
    font-size: 1.4rem;
    color: $grey-dark;

    > :not(:first-child) {
      margin-left: 0.75rem;
    }

    i,
    svg {
      width: $input-icon-height;
      height: $input-icon-height;
    }
  }

  .error-message {
    padding-top: 1rem;
  }
}

.ui.input {
  min-width: 100%;
}

.email {
  align-self: flex-start;
  width: 100%;

  .ui.input {
    width: 100%;

    input {
      background-color: $wrong-answer;
      border: none;
      height: 4.8rem;
      margin-bottom: 4.8rem;

      &:focus {
        background-color: $wrong-answer;

        &::placeholder {
          opacity: 0;
        }
      }

      &::placeholder {
        color: $grey-blue;
      }

      &::-moz-placeholder {
        opacity: 1;
      }
    }
  }
}
