@import '../_styles/colors';
@import '../_styles/breakpoints';

.question-wrapper {
  &.result-question {
    position: relative;
    z-index: 10000;
    margin: 0;

    @include breakpoint(tablet) {
      margin-top: 15.4rem;
    }

    img {
      margin: 0 auto;

      @include breakpoint(desktop) {
        margin: 0 6.4rem 0 0;
      }
    }

    h5 {
      font-family: 'PT-Sans-Bold';
      margin: 3.2rem 0 0.8rem;
      text-align: left;
      font-weight: bold;

      span {
        color: $red;
      }
    }

    .explanation {
      text-align: left;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 3.2rem;

      @include breakpoint(desktop) {
        bottom: 10.4rem;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
      }

      a {
        margin: 0 3.6rem;
        font-size: 1.4rem;

        @include breakpoint(tablet) {
          margin: 0 5.3rem;
        }
      }

      .arrow {
        .right {
          rotate: 180deg;
        }
      }
    }
  }
}
