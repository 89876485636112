@import '../../_styles/colors';
@import '../../_styles/breakpoints';

.answer {
  display: flex;
  align-items: center;
  border: 2px solid $border;
  margin-bottom: 1.2rem;
  cursor: pointer;
  padding: 1.2rem;

  &.is-result {
    pointer-events: none;
  }

  @include breakpoint(tablet) {
    padding: 2rem 2.4rem;
    bottom: 6.4rem;
    width: 57.6rem;
  }

  &.correct {
    border: 2px solid $green;
    background-color: $correct-answer;
  }

  &.selected {
    border: 2px solid $primary-color;
    background-color: $wrong-answer;
  }

  &.wrong {
    border: 2px solid $red;
    background-color: $wrong-answer;
  }

  .icon {
    margin: -0.4rem 1.6rem 0 0;
  }

  p {
    margin: 0;
  }
}
