@import '../../../_styles/colors';
@import '../../../_styles/breakpoints';

.outoftime-modal {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  @include breakpoint(tablet) {
    background-color: $background-transparent;
  }

  .close-icon {
    display: block;
    position: fixed;
    top: 7.2rem;
    right: 2.2rem;
    svg path {
      fill: $text-color;
    }

    @include breakpoint(tablet) {
      display: none;
    }

    @include breakpoint(desktop) {
      display: block;
      top: 12rem;

      svg path {
        fill: $white;
      }
    }
  }

  .info-wrapper {
    max-width: 64rem;
    background-color: $white;
    padding: 1.6rem;

    @include breakpoint(tablet) {
      padding: 4rem;
    }

    @include breakpoint(desktop) {
      padding: 8rem 8rem 4.8rem 8rem;
    }

    h2 {
      margin-bottom: 2.4rem;
    }

    p {
      margin-bottom: 1.6rem;
    }
  }

  button.close {
    display: none;

    @include breakpoint(tablet) {
      display: inline;
      position: fixed;
      bottom: 3.2rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
