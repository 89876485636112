@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.startscreen-wrapper {
  height: 100vh;
  background-image: url('../_assets/images/startscreen.jpg');
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .literals-wrapper {
    display: flex;
    flex-direction: column;
    color: $white;
    padding: 12.4rem 0 0 1.6rem;
    width: 95%;

    @include breakpoint(tablet) {
      width: 90%;
    }

    @include breakpoint(desktop) {
      margin: 0 auto;
      padding-top: 20rem;
      text-align: center;
    }

    ul {
      li {
        list-style: inside;
      }
    }
  }

  button {
    position: fixed;
    bottom: 8.8rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @include breakpoint(tablet) {
      bottom: 8.4rem;
    }

    @include breakpoint(desktop) {
      bottom: 16.2rem;
    }

    p {
      font-size: 1.3rem;

      @include breakpoint(tablet) {
        font-size: 1.6rem;
      }
    }

    i svg path {
      fill: $text-color;
    }
  }
}
