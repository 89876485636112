@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.login-wrapper {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;

  @include breakpoint(tablet) {
    margin-top: 12rem;
  }

  @include breakpoint(desktop) {
    margin-top: 19.2rem;
  }

  .title-wrapper {
    margin-bottom: 7.1rem;

    h1 {
      font-size: 2.4rem;
      text-align: center;

      @include breakpoint(tablet) {
        font-size: 4rem;
      }
    }
  }

  h3 {
    font-family: 'PT-Sans-Bold';
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }

  .form {
    .error-message {
      justify-content: center;
      margin-top: 1rem;
    }

    .ui.checkbox label:before {
      background-color: $grey-dark;
      width: 2rem;
      height: 2rem;
    }

    .ui.button.start-examination {
      margin: 2rem auto;

      svg path {
        fill: $text-color;
      }
    }
  }

  .mail-to {
    font-weight: bold;
    margin-top: 2rem;
    text-align: center;
  }
}
