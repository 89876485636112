// Campton
@font-face {
  font-family: 'CamptonBook-Italic';
  src: url('./_assets/_fonts/CamptonBook-Italic.otf');
}
@font-face {
  font-family: 'Campton-regular';
  src: url('./_assets/_fonts/CamptonBook.otf');
}
@font-face {
  font-family: 'CamptonBlack-Italic';
  src: url('./_assets/_fonts/CamptonBlack-Italic.otf');
}
@font-face {
  font-family: 'CamptonBlack';
  src: url('./_assets/_fonts/CamptonBlack.otf');
}
@font-face {
  font-family: 'CamptonBold-Italic';
  src: url('./_assets/_fonts/CamptonBold-Italic.otf');
}
@font-face {
  font-family: 'CamptonBold';
  src: url('./_assets/_fonts/CamptonBold.otf');
}
@font-face {
  font-family: 'CamptonExtraBold-Italic';
  src: url('./_assets/_fonts/CamptonExtraBold-Italic.otf');
}
@font-face {
  font-family: 'CamptonExtraBold';
  src: url('./_assets/_fonts/CamptonExtraBold.otf');
}
@font-face {
  font-family: 'CamptonExtraLight-Italic';
  src: url('./_assets/_fonts/CamptonExtraLight-Italic.otf');
}
@font-face {
  font-family: 'CamptonLight-Italic';
  src: url('./_assets/_fonts/CamptonLight-Italic.otf');
}
@font-face {
  font-family: 'CamptonLight';
  src: url('./_assets/_fonts/CamptonLight.otf');
}
@font-face {
  font-family: 'CamptonMedium-Italic';
  src: url('./_assets/_fonts/CamptonMedium-Italic.otf');
}
@font-face {
  font-family: 'CamptonMedium';
  src: url('./_assets/_fonts/CamptonMedium.otf');
}
@font-face {
  font-family: 'CamptonSemiBold-Italic';
  src: url('./_assets/_fonts/CamptonSemiBold-Italic.otf');
}
@font-face {
  font-family: 'CamptonSemiBold';
  src: url('./_assets/_fonts/CamptonSemiBold.otf');
}
@font-face {
  font-family: 'CamptonThin-Italic';
  src: url('./_assets/_fonts/CamptonThin-Italic.otf');
}
@font-face {
  font-family: 'CamptonThin';
  src: url('./_assets/_fonts/CamptonThin.otf');
}

// PT-Sans

@font-face {
  font-family: 'PT-Sans';
  src: url('./_assets/_fonts/PTC55F.ttf');
}
@font-face {
  font-family: 'PT-Sans-Bold';
  src: url('./_assets/_fonts/PTC75F.ttf');
}
@font-face {
  font-family: 'PT-Sans-Narrow';
  src: url('./_assets/_fonts/PTN57F.ttf');
}
@font-face {
  font-family: 'PT-Sans-Narrow-Bold';
  src: url('./_assets/_fonts/PTN77F.ttf');
}
@font-face {
  font-family: 'PTS55F';
  src: url('./_assets/_fonts/PTS55F.ttf');
}
@font-face {
  font-family: 'PTS56F';
  src: url('./_assets/_fonts/PTS56F.ttf');
}
@font-face {
  font-family: 'PTS75F';
  src: url('./_assets/_fonts/PTS75F.ttf');
}
@font-face {
  font-family: 'PTS76F';
  src: url('./_assets/_fonts/PTS76F.ttf');
}
