@import '../../../_styles/colors';
@import '../../../_styles/constants';
@import '../../../_styles/breakpoints';

header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $white;
  z-index: 10000;
  height: $mobile-navbar-height;

  &.is-kbc-behaviour {
    height: $kbc-navbar-height;
  }

  @include breakpoint(desktop) {
    height: $desktop-navbar-height;
  }

  a {
    &.is-kbc-behaviour {
      pointer-events: none;
    }

    img {
      width: 8rem;
      margin: 1.2rem auto;
      display: block;
      cursor: pointer;

      @include breakpoint(tablet) {
        margin-top: 1.5rem;
      }
    }
  }

  .timer {
    background-color: $grey-light;
    width: 100%;
    height: $progressbar-height;
    position: absolute;
    bottom: 0;
  }

  .language {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    cursor: pointer;
    color: $grey;

    > span {
      &:nth-child(2) {
        margin-left: 0.6rem;
      }

      &.is-active {
        font-weight: bold;
      }
    }
  }
}
