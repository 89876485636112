@import '../../_styles/colors';
@import '../../_styles/breakpoints';

.base-modal {
  .close-icon {
    right: 0rem;
    top: 0rem;
    position: fixed;
    color: $white;
  }

  .actions {
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  .content {
    margin: 0;
    margin-bottom: 2rem;

    > :not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  .ui.primary.close {
    @include breakpoint(desktop) {
      position: relative;
    }
  }

  .cta-wrapper {
    display: flex;
    background: none;
    margin: 3.2rem;
    justify-content: center;

    @include breakpoint(desktop) {
      position: relative;
      background-color: $white;
      justify-content: center;
      margin: 4.8rem;
      padding: 0 3rem 2rem;
    }
  }
}
