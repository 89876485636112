@import './constants';

@mixin breakpoint($point) {
  @if $point == tablet {
    @media (min-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $desktop-breakpoint) {
      @content;
    }
  } @else if $point == monitor {
    @media (min-width: $monitor-breakpoint) {
      @content;
    }
  } @else if $point == big-monitor {
    @media (min-width: $big-monitor-breakpoint) {
      @content;
    }
  }
}
