@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.result-wrapper {
  margin-top: $mobile-navbar-height;

  &.is-kbc-behaviour {
    margin-top: 0;
  }

  @include breakpoint(desktop) {
    margin-top: $desktop-navbar-height + 6.4rem;
    display: flex;
    padding: 0 14rem;
    justify-content: center;
  }

  .img-wrapper {
    width: 100%;
    background-color: $green-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 1.5rem 2.4rem 2.4rem;
    color: $green;

    &.isWrong {
      background-color: $red-light;
      color: $red;
    }

    @include breakpoint(tablet) {
      flex-direction: column-reverse;
      padding: 6rem;
    }

    @include breakpoint(desktop) {
      max-width: 43rem;
      width: 43rem;
      height: 57rem;
    }

    .info {
      h3 {
        font-size: 3.2rem;
        margin-bottom: 1.6rem;

        @include breakpoint(tablet) {
          font-size: 8rem;
          text-align: center;
        }
      }

      p {
        font-size: 1.6rem;
        max-width: 15rem;
        color: $text-color;

        @include breakpoint(tablet) {
          max-width: initial;
        }

        @include breakpoint(desktop) {
          text-align: center;
          width: 90%;
          margin: 0 auto;
        }
      }
    }

    img {
      width: 35%;

      @include breakpoint(tablet) {
        width: 20%;
        margin-bottom: 3rem;
      }

      @include breakpoint(desktop) {
        width: 80%;
      }
    }
  }

  .results {
    margin-bottom: 5rem;

    @include breakpoint(desktop) {
      margin-left: 10.7rem;
    }

    h2 {
      font-size: 1.6rem;
      text-align: center;
      margin: 2.4rem 0;

      @include breakpoint(tablet) {
        margin: 6.4rem 0 3.4rem 0;
      }

      @include breakpoint(desktop) {
        text-align: left;
      }
    }

    .questions-wrapper {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      justify-items: center;
      padding: 0 3rem 3rem 3rem;

      @include breakpoint(tablet) {
        grid-template-columns: repeat(14, 1fr);
        padding: 0 9rem 4.8rem 9rem;
      }

      @include breakpoint(desktop) {
        grid-template-columns: repeat(15, 1fr);
        padding: 0;
      }

      .question {
        margin-bottom: 2.3rem;
        width: 2.8rem;
        height: 2.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include breakpoint(desktop) {
          margin: 0 2.6rem 2.3rem 0;
        }

        &:first-child {
          margin-left: 0;
        }

        &.isWrong {
          color: $red;
          font-family: 'CamptonBold';
        }

        &.isMajorMistake {
          background-color: $red;
          color: $white;
          padding: 0.6rem;
          border-radius: 50%;
        }
      }
    }

    p {
      text-align: center;
    }

    a {
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 2.4rem;
      margin-top: 2rem;

      @include breakpoint(desktop) {
        margin-top: 0;
        left: 0;
        top: 10rem;
        transform: initial;
      }
    }
  }
}
