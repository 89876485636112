$font-family: 'PT-Sans', sans-serif;

$desktop-navbar-height: 9.6rem;
$mobile-navbar-height: 7rem;
$progressbar-height: 0.5rem;
$kbc-navbar-height: 0.5rem;

$input-height: 4rem;
$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;

$big-monitor-breakpoint: 1920px;
$monitor-breakpoint: 1280px;
$desktop-breakpoint: 1025px;
$tablet-breakpoint: 415px;
